// Libraries
import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";

// Local
import Layout from "../Common/Layout";

const TermsOfUse = () => (
  <Layout>
    <Container
      sx={{
        marginTop: "100px",
        color: "white",
        textAlign: "left",
      }}
    >
      <Typography variant="h3" gutterBottom>
        Terms of Use (In progress)
      </Typography>

      <Typography variant="h5" gutterBottom>
        Endocite Association
      </Typography>

      <Typography variant="body1" paragraph>
        These Terms of Use govern your use of the AI academic writing tool
        provided by the Endocite Association. By accessing or using the AI
        academic writing tool, you agree to these Terms of Use.
      </Typography>

      <Divider />

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          Membership Tiers
        </Typography>

        <Typography variant="body1" paragraph>
          The Endocite Association offers three tiers of membership: Active,
          Passive, and Patron. Each tier offers different levels of access to
          the AI academic writing tool.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Active Membership
        </Typography>
        <Typography variant="body2" paragraph>
          The Active Membership offers basic access to the AI academic writing
          tool, including...
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Passive Membership
        </Typography>
        <Typography variant="body2" paragraph>
          The Passive Membership offers advanced access to the AI academic
          writing tool, including...
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Patron Membership
        </Typography>
        <Typography variant="body2" paragraph>
          The Patron Membership offers premium access to the AI academic writing
          tool, including...
        </Typography>
      </Box>

      <Divider />

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          Termination of Membership
        </Typography>

        <Typography variant="body1" paragraph>
          The Endocite Association reserves the right to terminate your
          membership at any time if you violate these Terms of Use...
        </Typography>
      </Box>
    </Container>
  </Layout>
);

export default TermsOfUse;
