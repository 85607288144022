// Libraries
import React, { useState } from "react";
import { Stack, Box, Fade, Typography, Button, CardMedia } from "@mui/material";

// Local
import Layout from "../Common/Layout";
import Version from "../Common/Version";

const Services = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Layout>
      <CardMedia
        component="img"
        src="/business.jpg"
        sx={{
          paddingTop: "100px",
          width: "100%",
          height: "300px",
        }}
      />
      <Fade in={true} mountOnEnter timeout={1500}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-evenly",
            alignItems: "center",
            paddingTop: { xs: "100px", md: "100px" },
            height: "100%",
            width: "100%",
          }}
        >
          <Stack
            direction="column"
            sx={{
              justifyContent: "space-between",
              textAlign: "center",
              border: "1px solid white",
              color: "white",
              padding: "20px",
              minHeight: "275px",
              height: "auto",
              boxSizing: "border-box",
              marginBottom: { xs: "20px", md: "0px" },
              width: { xs: "100%", md: "400px" },
            }}
          >
            <Stack direction="column">
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                For Universities and Companies
              </Typography>
              <Typography variant="body1">
                Set up our platform as an in-house service at your University or
                Company. Contact us for more information at
                contact@endocite.org.
              </Typography>
            </Stack>
            <Button
              variant="outlined"
              sx={{
                marginTop: "20px",
                borderRadius: "0px",
                borderColor: "white",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "white",
                },
              }}
              onClick={() => {
                window.location.href = "mailto:contact@endocite.org";
              }}
            >
              Contact us
            </Button>
          </Stack>
          <Stack
            direction="column"
            sx={{
              justifyContent: "space-between",
              textAlign: "center",
              border: "1px solid white",
              color: "white",
              padding: "20px",
              minHeight: "275px",
              height: "auto",
              boxSizing: "border-box",
              marginBottom: { xs: "20px", md: "0px" },
              width: { xs: "100%", md: "400px" },
            }}
          >
            <Stack direction="column">
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                For individuals
              </Typography>
              <Typography variant="body1">
                Endoc, our web application.
              </Typography>
            </Stack>
            <Button
              variant="outlined"
              sx={{
                marginTop: "20px",
                borderRadius: "0px",
                borderColor: "white",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "white",
                },
              }}
              onClick={() => {
                setIsModalOpen(true);
              }}
            >
              Try Endoc
            </Button>
          </Stack>
          <Stack
            direction="column"
            sx={{
              justifyContent: "space-between",
              textAlign: "center",
              border: "1px solid white",
              color: "white",
              padding: "20px",
              minHeight: "275px",
              height: "auto",
              boxSizing: "border-box",
              marginBottom: { xs: "20px", md: "0px" },
              width: { xs: "100%", md: "400px" },
            }}
          >
            <Stack direction="column">
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                For Developers
              </Typography>
              <Typography variant="body1">
                Use our API to integrate our discovery, summarization, and
                citation services into your own platform. Contact us for more
                information at contact@endocite.org.
              </Typography>
            </Stack>
            <Button
              variant="outlined"
              sx={{
                marginTop: "20px",
                borderRadius: "0px",
                borderColor: "white",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "white",
                },
              }}
              onClick={() => {
                window.location.href = "mailto:contact@endocite.org";
              }}
            >
              Contact us
            </Button>
          </Stack>
        </Box>
      </Fade>
      <Version isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </Layout>
  );
};

export default Services;
