import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import Copyright from "./Copyright";

const footers = [
  {
    title: "Endocite",
    description: [
      { text: "Services", path: "/services" },
      { text: "About", path: "/about" },
    ],
  },
  {
    title: "Legal",
    description: [
      { text: "Privacy policy", path: "/privacy" },
      { text: "Terms of use", path: "/terms" },
    ],
  },
];

const Footer = () => (
  <Container
    maxWidth="md"
    component="footer"
    sx={{
      py: [3, 6],
      color: "white",
      textAlign: "left !important",
    }}
  >
    <Grid container spacing={4} justifyContent="space-evenly">
      {footers.map((footer) => (
        <Grid item xs={6} sm={3} key={footer.title}>
          <Typography variant="h6" gutterBottom>
            {footer.title}
          </Typography>
          <ul>
            {footer.description.map((item) => (
              <li key={item.text}>
                <Link href={item.path} variant="subtitle1">
                  {item.text}
                </Link>
              </li>
            ))}
          </ul>
        </Grid>
      ))}
    </Grid>
    <Copyright sx={{ mt: 5 }} />
  </Container>
);

export default Footer;
