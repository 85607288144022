import * as React from "react";
import Typography from "@mui/material/Typography";

const Copyright = (props) => (
  <Typography variant="body2" align="center" {...props}>
    {"Founded in Switzerland 🇨🇭 | © Endocite "}
    {new Date().getFullYear()}
  </Typography>
);

export default Copyright;
