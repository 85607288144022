import React, { useState, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import Menu from "../Common/Menu";
import Footer from "../Common/Footer";

const Layout = ({ children }) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef();

  useEffect(() => {
    const updateScrollPosition = () => {
      const position = contentRef.current.scrollTop;
      setScrollPosition(position);
    };

    const contentElement = contentRef.current;
    contentElement.addEventListener("scroll", updateScrollPosition);
    return () =>
      contentElement.removeEventListener("scroll", updateScrollPosition);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        width: "100%",
        overflow: "hidden",
        background: "black",
      }}
    >
      <Menu scrollPosition={scrollPosition} />
      <Box
        ref={contentRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          height: "auto",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        {children}
        <Footer />
      </Box>
    </Box>
  );
};

export default Layout;
