// Libraries
import React from "react";
import { Container, Typography, Box, Divider } from "@mui/material";

// Local
import Layout from "../Common/Layout";

const PrivacyPolicy = () => (
  <Layout>
    <Container
      sx={{
        marginTop: "100px",
        color: "white",
        textAlign: "left",
      }}
    >
      <Typography variant="h3" gutterBottom>
        Privacy Policy (In progress)
      </Typography>

      <Typography variant="h5" gutterBottom>
        Endocite Association, Zurich, Switzerland
      </Typography>

      <Typography variant="body1" paragraph>
        This Privacy Policy explains how the Endocite Association collects,
        uses, and discloses your personal information in relation to your use of
        the AI academic writing tool. By using the tool, you agree to the terms
        of this Privacy Policy.
      </Typography>

      <Divider />

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          Membership Tiers
        </Typography>

        <Typography variant="body1" paragraph>
          The Endocite Association offers three tiers of membership: Active,
          Passive, and Patron. Each tier has different privacy considerations.
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Active Membership
        </Typography>
        <Typography variant="body2" paragraph>
          Active Memberships involve the collection of data including...
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Passive Membership
        </Typography>
        <Typography variant="body2" paragraph>
          Passive Memberships involve the collection of data including...
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          Patron Membership
        </Typography>
        <Typography variant="body2" paragraph>
          Patron Memberships involve the collection of data including...
        </Typography>
      </Box>

      <Divider />

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          Data Usage
        </Typography>

        <Typography variant="body1" paragraph>
          The Endocite Association uses your data for the purposes of...
        </Typography>
      </Box>

      <Box my={3}>
        <Typography variant="h6" gutterBottom>
          Data Disclosure
        </Typography>

        <Typography variant="body1" paragraph>
          The Endocite Association discloses your data in the following
          circumstances...
        </Typography>
      </Box>
    </Container>
  </Layout>
);

export default PrivacyPolicy;
