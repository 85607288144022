import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Stack,
  Button,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import LastPageIcon from "@mui/icons-material/LastPage";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Logo from "./logo.svg";
import Version from "./Version";

const MenuBar = ({ scrollPosition }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const opacity = Math.min(scrollPosition / 150, 1);
  const location = useLocation();
  const currentPath = location.pathname;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const scrollToDiv = () => {
    if (currentPath === "/") {
      const targetDiv = document.getElementById("memberships");
      targetDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      navigate(`/#memberships`);
    }
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        color: "white",
        zIndex: 1,
        background: () => `rgba(0, 0, 0, ${opacity})`,
        position: "absolute",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Toolbar
        sx={{
          justifyContent: "space-between",
          boxSizing: "border-box",
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/");
          }}
        >
          <Box
            component="img"
            src={Logo}
            alt="logo"
            sx={{ width: { xs: "100px", md: "125px" }, height: "auto" }}
          />
        </Stack>
        <Stack direction="row">
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={scrollToDiv}
            sx={{
              display: { xs: "inline-flex", sm: "none" },
              borderRadius: "0px",
              marginRight: "10px",
              "&:hover": {
                color: "black",
                backgroundColor: "white",
                borderColor: "white",
              },
            }}
          >
            Membership
          </Button>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            size="small"
            variant="outlined"
            color="inherit"
            endIcon={<KeyboardArrowDownIcon />}
            sx={{
              display: { xs: "inline-flex", sm: "none" },
              borderRadius: "0px",
              "&:hover": {
                color: "white",
                backgroundColor: "black",
                borderLeft: "1px solid white",
                borderRight: "1px solid white",
                borderTop: "1px solid white",
                borderBottom: "black",
              },
            }}
          >
            Menu
          </Button>
        </Stack>
        <Version isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          sx={{
            width: "100%",
            height: "100%",
            "& .MuiPaper-root": {
              width: "100%",
              background: "black",
              color: "white",
              borderRadius: "0px",
              border: "1px solid white",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              navigate("/services");
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>Services</Typography>
            <LastPageIcon />
          </MenuItem>
          <Divider
            variant="middle"
            sx={{
              background: "white",
            }}
          />
          <MenuItem
            onClick={() => {
              navigate("/about");
            }}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography>About</Typography>
            <LastPageIcon />
          </MenuItem>
        </Menu>
        <Stack
          direction="row"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            endIcon={<KeyboardArrowDownIcon />}
            onClick={scrollToDiv}
            sx={{
              borderRadius: "0px",
              marginRight: "10px",
              "&:hover": {
                color: "black",
                backgroundColor: "white",
                borderColor: "white",
              },
            }}
          >
            Membership
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            endIcon={<LastPageIcon />}
            onClick={() => {
              navigate("/services");
            }}
            sx={{
              borderRadius: "0px",
              marginRight: "10px",
              "&:hover": {
                color: "black",
                backgroundColor: "white",
                borderColor: "white",
              },
            }}
          >
            Services
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            endIcon={<LastPageIcon />}
            onClick={() => {
              navigate("/about");
            }}
            sx={{
              borderRadius: "0px",
              marginRight: "10px",
              "&:hover": {
                color: "black",
                backgroundColor: "white",
                borderColor: "white",
              },
            }}
          >
            About
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="inherit"
            endIcon={<OpenInNewOutlinedIcon />}
            onClick={() => {
              setIsModalOpen(true);
            }}
            sx={{
              borderRadius: "0px",
              "&:hover": {
                color: "black",
                backgroundColor: "white",
                borderColor: "white",
              },
            }}
          >
            Try Endoc
          </Button>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default MenuBar;
