// Libraries
import React from "react";
import {
  Stack,
  Typography,
  Fade,
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
} from "@mui/material";
import Typewriter from "typewriter-effect";

// Local
import Layout from "../Common/Layout";

const team = [
  {
    first_name: "Richard",
    surname: "Hahnloser",
    image_url: "https://placehold.co/400",
    description: "President",
    linkedin: "https://www.linkedin.com/in/richard-hahnloser-542965101/",
  },
  {
    first_name: "Nianlong",
    surname: "Gu",
    image_url: "https://placehold.co/400",
    description: "Vice-President",
    linkedin: "https://www.linkedin.com/in/nianlonggu/",
  },
  {
    first_name: "Jhony",
    surname: "Prada",
    image_url: "https://placehold.co/400",
    description: "Finance",
    linkedin: "https://www.linkedin.com/in/jhonyprada/",
  },
  {
    first_name: "Jessica",
    surname: "Lam",
    image_url: "https://placehold.co/400",
    description: "Legal",
    linkedin: "https://www.linkedin.com/in/jessicalamjh/",
  },
  {
    first_name: "Yingqiang",
    surname: "Gao",
    image_url: "https://placehold.co/400",
    description: "Actuarial",
    linkedin: "https://www.linkedin.com/in/yingqiang-gao/",
  },
];

const About = () => (
  <Layout>
    <CardMedia
      component="img"
      src="/ai.jpg"
      sx={{
        paddingTop: "100px",
        width: "100%",
        height: "300px",
      }}
    />
    <Stack
      direction="column"
      sx={{
        width: "100%",
        height: "auto",
        justifyContent: "center",
        alignItems: "center",
        color: "white",
        marginTop: { xs: "25px", md: "50px" },
        boxSizing: "border-box",
      }}
    >
      <Typography
        gutterBottom
        variant="h5"
        sx={{
          boxSizing: "border-box",
          width: "100%",
          maxWidth: "800px",
        }}
      >
        The Endocite association promotes natural language algorithms for
        scientific writing. Our mission is to create useful tools to help
        authors assimilate the scientific literature. We are based in Zurich,
        Switzerland.
      </Typography>
      <Typography
        variant="body1"
        sx={{
          boxSizing: "border-box",
          width: "100%",
          maxWidth: "500px",
          marginBottom: "50px",
        }}
      >
        The name Endocite derives from endocytosis, which is the biological
        process of packing up fluids or macromolecules into small vesicles. By
        analogy with this term, our algorithms discover useful items in the
        scientific literature and pack them up as citations in a manuscript.
      </Typography>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          width: "100%",
          textAlign: "center",
          color: "white",
          marginBottom: "50px",
        }}
      >
        <Typewriter
          onInit={(typewriter) => {
            typewriter.typeString("Board Members").changeDelay(50).start();
          }}
        />
      </Typography>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 6, md: 12 }}
        sx={{
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {team.map((member, index) => (
          <Fade in={true} timeout={2000} key={index}>
            <Card
              onClick={() => {
                if (member.linkedin) {
                  window.open(member.linkedin, "_blank");
                }
              }}
              sx={{
                width: { xs: "100%", md: 345 },
                margin: "20px",
                textAlign: "center",
                boxSizing: "border-box",
                backgroundColor: "black",
                border: "1px solid white",
                color: "white",
                borderRadius: "0px",
                cursor: member.linkedin !== "" ? "pointer" : "default",
                "&:hover": {
                  backgroundColor: member.linkedin !== "" ? "#163a78" : "black",
                },
              }}
              elevation={4}
            >
              <CardActionArea
                disableRipple
                sx={{
                  textAlign: "center",
                }}
              >
                <CardContent
                  sx={{
                    textAlign: "center",
                  }}
                >
                  <Typography gutterBottom variant="h5" component="div">
                    {member.first_name} {member.surname}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Fade>
        ))}
      </Grid>
    </Stack>
  </Layout>
);

export default About;
