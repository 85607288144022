import React from "react";
import { Box, Typography, Stack, Button, Modal, Link } from "@mui/material";

const Version = ({ isModalOpen, setIsModalOpen }) => (
  <Modal
    open={isModalOpen}
    onClose={() => setIsModalOpen(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "black",
        border: "2px solid #000",
        boxShadow: 24,
        color: "white",
        p: 4,
      }}
    >
      <Typography
        id="version-select-title"
        variant="h6"
        component="h2"
        sx={{
          marginBottom: "20px",
        }}
      >
        Choose your version:
      </Typography>
      <Stack direction={"row"} spacing={2}>
        <Button
          sx={{
            color: "black",
            backgroundColor: "white",
            padding: "20px",
            width: "100%",
            border: "2px solid white",
            "&:hover": {
              color: "black",
              backgroundColor: "white",
              border: "2px solid cornflowerblue",
            },
          }}
          onClick={() => {
            window.open(
              "https://endoc.ethz.ch",
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          <Typography variant="body1">
            Endoc <br /> via <br /> *ethz.ch
          </Typography>
        </Button>
        <Button
          sx={{
            flexDirection: "column",
            color: "black",
            backgroundColor: "white",
            padding: "20px",
            width: "100%",
            border: "2px solid white",
            "&:hover": {
              color: "black",
              backgroundColor: "white",
              border: "2px solid cornflowerblue",
            },
          }}
          onClick={() => {
            window.open(
              "https://app.endocite.org",
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          <Typography variant="body1" gutterBottom>
            Endoc <br /> via endocite.org
          </Typography>
          <Typography variant="caption">(Under maintenance)</Typography>
        </Button>
      </Stack>
      <Typography
        id="version-select-description"
        sx={{ mt: 2 }}
        variant="caption"
      >
        *You can try the full version of Endoc for free via your Switch edu-ID
        account if affiliated to a supported Swiss university or research
        institute.{" "}
        <Link
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_PLATFORM_DOCUMENTATION}/documentation/overview/quick-start`,
              "_blank",
              "noreferrer"
            );
          }}
          sx={{
            cursor: "pointer",
          }}
        >
          See documentation.
        </Link>
      </Typography>
    </Box>
  </Modal>
);

export default Version;
