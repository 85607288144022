// Libraries
import React, { useState, useRef, useEffect } from "react";
import {
  Stack,
  Box,
  Fade,
  Slide,
  Typography,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  CardMedia,
} from "@mui/material";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// Local
import Layout from "../Common/Layout";

const Home = () => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [checked, setChecked] = useState(false);
  const [runOnce, setRunOnce] = useState(false); // new state variable

  const ref = useRef();

  useEffect(() => {
    const currentRef = ref.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        // if entry is intersecting and runOnce is false, set checked to true
        if (entry.isIntersecting && !runOnce) {
          setChecked(true);
          setRunOnce(true); // set runOnce to true so this won't run again
        }
      },
      { threshold: 0.1 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    // cleanup on unmount
    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [runOnce]); // runOnce as a dependency so the effect will rerun when runOnce changes

  useEffect(() => {
    const element = document.getElementById(window.location.hash.substring(1));
    if (element) element.scrollIntoView({ behavior: "smooth", block: "start" });
  }, []);

  return (
    <Layout>
      <Fade in={true} timeout={2000}>
        <Box
          sx={{
            width: "100%",
            height: "100vh",
          }}
        >
          <CardMedia
            ref={videoRef}
            component="video"
            src="/main.mp4"
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
          <Slide direction="right" in={true} mountOnEnter timeout={1500}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignItems: "flex-start",
                textAlign: "left",
                position: "relative",
                background: "rgba(0,0,0,0.5)",
                border: "1px solid cornflowerblue",
                color: "white",
                top: { xs: "-450px", md: "-550px" },
                right: { xs: "0px", md: "-20px" },
                padding: "20px",
                boxSizing: "border-box",
                width: { xs: "100%", sm: "450px", md: "550px" },
                minHeight: "250px",
                height: "auto",
              }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                Transparent technology for responsible science.
              </Typography>
              <Typography variant="body1">
                We provide a platform for scientific writing and discovery
                powered by large language models. Propel your science with AI.
              </Typography>
            </Box>
          </Slide>
          <Button
            size="small"
            variant="outlined"
            startIcon={
              isPlaying ? (
                <PauseCircleOutlineOutlinedIcon />
              ) : (
                <PlayArrowOutlinedIcon />
              )
            }
            sx={{
              position: "relative",
              top: { xs: "-400px", sm: "-375px", md: "-350px" },
              left: { xs: "0px", sm: "250px", md: "420px", lg: "500px" },
              marginTop: "20px",
              borderRadius: "0px",
              width: "100px",
              borderColor: "white",
              color: "white",
              "&:hover": {
                color: "black",
                backgroundColor: "white",
                borderColor: "white",
              },
            }}
            onClick={() => {
              if (isPlaying) {
                videoRef.current.pause();
              } else {
                videoRef.current.play();
              }
              setIsPlaying(!isPlaying);
            }}
          >
            {isPlaying ? "Pause" : "Play"}
          </Button>
        </Box>
      </Fade>

      <Box
        ref={ref}
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          padding: "50px",
        }}
      >
        <Slide direction="right" in={checked} mountOnEnter timeout={1500}>
          <Stack
            direction="column"
            sx={{
              justifyContent: "space-between",
              textAlign: "left",
              border: "1px solid white",
              color: "white",
              padding: "20px",
              minHeight: "250px",
              background: "#1f1f1f",
              height: "auto",
              boxSizing: "border-box",
              marginBottom: { xs: "20px", md: "0px" },
              width: { xs: "100%", md: "400px" },
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: "bold",
              }}
            >
              Responsible Science: we give you the tools.
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="We offer an easy writing experience enhanced with tools for literature discovery and citation generation." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="No longer ignore or mis-cite relevant works." />
              </ListItem>
            </List>
          </Stack>
        </Slide>
        <Fade in={checked} timeout={2000}>
          <Stack
            direction="column"
            sx={{
              justifyContent: "space-between",
              textAlign: "left",
              border: "1px solid cornflowerblue",
              color: "white",
              padding: "20px",
              minHeight: "250px",
              height: "auto",
              background:
                "linear-gradient(to bottom right, #000000, #1f1f1f 50%, #6495ED 100%)",
              boxSizing: "border-box",
              marginBottom: { xs: "20px", md: "0px" },
              width: { xs: "100%", md: "400px" },
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: "bold",
              }}
            >
              Endoc
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our main product, Endoc is a smart web-based text editor that
              makes use of Natural Language Processing (NLP).
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <InfoOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary="We help researchers find semantic content, write scientific papers,
            draft proposals, and review other people’s work."
                />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <InfoOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Our mission is to help researchers focus their attention on ideas, results, and the implications of their work, whilst minimizing the time they spend on cognitively demanding but routine tasks such as literature searching, narrowing of search results, and skimming through selected papers for information." />
              </ListItem>
            </List>
          </Stack>
        </Fade>
        <Slide direction="left" in={checked} mountOnEnter timeout={1500}>
          <Stack
            direction="column"
            sx={{
              justifyContent: "space-between",
              textAlign: "left",
              border: "1px solid white",
              color: "white",
              padding: "20px",
              minHeight: "250px",
              height: "auto",
              boxSizing: "border-box",
              background: "#1f1f1f",
              marginBottom: { xs: "20px", md: "0px" },
              width: { xs: "100%", md: "400px" },
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontWeight: "bold",
              }}
            >
              Transparent Technology: no compromise on performance
            </Typography>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Our tools achieve state-of-the-art performance on paper summarization (highlighting), citation recommendation (semantic search), and citation (sentence) generation." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary=" Our natural language and information retrieval tools are available as open-source code." />
              </ListItem>
            </List>
          </Stack>
        </Slide>
      </Box>
      <Fade in={true} timeout={2000}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Stack
            direction="column"
            sx={{
              height: "auto",
              justifyContent: "center",
              padding: { xs: "20px", md: "100px" },
              borderTop: "1px solid white",
              boxSizing: "border-box",
              alignItems: "center",
              color: "white",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                marginTop: "20px",
                width: "100%",
                maxWidth: "800px",
              }}
            >
              Try out our Endoc platform on a small database of all Pubmed
              Central Open Access (PMCOA) papers. ​
            </Typography>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                width: "100%",
                maxWidth: "600px",
                marginBottom: "40px",
              }}
            >
              For access to upgraded tools and for searching a larger paper
              database of 100 million plus open-access papers, join our
              association.
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              sx={{
                marginBottom: "20px",
                width: "100%",
                maxWidth: "600px",
              }}
            >
              Note: search times on large paper databases can take longer.
            </Typography>
            <Button
              variant="filled"
              sx={{
                borderRadius: "0px",
                width: "auto",
                border: "1px solid cornflowerblue",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                  border: "1px solid cornflowerblue",
                },
              }}
              onClick={() => {
                window.location.href = "https://app.endocite.org";
              }}
            >
              Try Endoc
            </Button>
          </Stack>
        </Box>
      </Fade>
      <CardMedia
        component="img"
        src="/library.jpg"
        sx={{
          width: "100%",
          height: "auto",
        }}
      />
      <Stack
        id="memberships"
        direction="column"
        sx={{
          width: "100%",
          padding: { xs: "40px", md: "50px" },
          textAlign: "center",
          color: "white !important",
        }}
      >
        <Typography variant="h2">Membership</Typography>
        <Typography variant="body1">
          We provide three types of memberships of the Endocite association:
        </Typography>
      </Stack>
      <Fade in={true} timeout={3000}>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "space-evenly",
            alignItems: "flex-start",
            width: "100%",
            padding: "50px",
          }}
        >
          <Stack
            direction="column"
            sx={{
              justifyContent: "space-between",
              textAlign: "left",
              border: "1px solid white",
              color: "white",
              padding: "20px",
              minHeight: "250px",
              height: "auto",
              background: "#1f1f1f",
              boxSizing: "border-box",
              marginBottom: { xs: "20px", md: "0px" },
              width: { xs: "100%", md: "400px" },
            }}
          >
            <Stack
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                Contributor
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                Active membership
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                (20 CHF per year)
              </Typography>
            </Stack>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Join our team of contributors in creating next-generation tools for scientific writing." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Connect with researchers that share your interests." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Shape the tools you want to use for advancing your science." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Together achieve more than you could achieve alone." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Connect your publication dataset to our infrastructure and contribute to open science." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Note: active members are expected to actively contribute to our association, else they are downgraded to passive membership." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Enjoy all benefits included with a passive membership." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="To apply, contact us at contact@endocite.org." />
              </ListItem>
            </List>
            <Button
              variant="outlined"
              sx={{
                marginTop: "20px",
                borderRadius: "0px",
                borderColor: "white",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "white",
                },
              }}
              onClick={() => {
                window.open(
                  "https://buy.stripe.com/28o8xBaWrgXI1pueUW",
                  "_blank"
                );
              }}
            >
              Become a contributor
            </Button>
          </Stack>
          <Stack
            direction="column"
            sx={{
              justifyContent: "space-between",
              textAlign: "left",
              border: "1px solid cornflowerblue",
              color: "white",
              padding: "20px",
              minHeight: "250px",
              background:
                "linear-gradient(to bottom right, #000000, #1f1f1f 50%, #6495ED 100%)",
              height: "auto",
              boxSizing: "border-box",
              marginBottom: { xs: "20px", md: "0px" },
              width: { xs: "100%", md: "400px" },
            }}
          >
            <Stack
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                Regular
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                Passive membership
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                (100 CHF per year)
              </Typography>
            </Stack>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Use our one-stop shop for writing, searching, reading, and citing of scientific works." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Navigate through research with confidence, and discover the text that matters." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Use hybrid search: uncompromising freedom to combine traditional keyword-based search and modern semantic retrieval." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Our search and text generation tools have unmatched performance, see our publications." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Your Endoc registered email account will be upgraded to regular membership." />
              </ListItem>
            </List>
            <Button
              variant="outlined"
              sx={{
                marginTop: "20px",
                borderRadius: "0px",
                border: "1px solid cornflowerblue",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "cornflowerblue",
                },
              }}
              onClick={() => {
                window.open(
                  "https://buy.stripe.com/bIY9BF2pVbDo9W0aEH",
                  "_blank"
                );
              }}
            >
              Join now
            </Button>
          </Stack>
          <Stack
            direction="column"
            sx={{
              justifyContent: "space-between",
              textAlign: "left",
              border: "1px solid white",
              color: "white",
              padding: "20px",
              minHeight: "250px",
              background: "#1f1f1f",
              height: "auto",
              boxSizing: "border-box",
              marginBottom: { xs: "20px", md: "0px" },
              width: { xs: "100%", md: "400px" },
            }}
          >
            <Stack
              sx={{
                textAlign: "center",
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                Enterprise
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                Patron membership
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: "bold",
                }}
              >
                (1000 CHF per year)
              </Typography>
            </Stack>
            <List>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Learn first-hand where the association’s efforts are headed." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Learn about our short-term goals and work plans." />
              </ListItem>
              <ListItem disablePadding>
                <ListItemIcon>
                  <CheckCircleOutlinedIcon
                    sx={{
                      color: "white",
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary="Partake in exclusive events for patrons." />
              </ListItem>
            </List>
            <Button
              variant="outlined"
              sx={{
                marginTop: "20px",
                borderRadius: "0px",
                borderColor: "white",
                color: "white",
                "&:hover": {
                  color: "black",
                  backgroundColor: "white",
                  borderColor: "white",
                },
              }}
              onClick={() => {
                window.location.href = "mailto:contact@endocite.org";
              }}
            >
              Contact us
            </Button>
          </Stack>
        </Box>
      </Fade>
    </Layout>
  );
};

export default Home;
